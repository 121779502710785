<template>
  <div class="donate">
    <div class="oItem-t">
      <div class="title">公益捐赠</div>
    </div>
    <div class="oItem-c">
      <v-input
        type="textarea"
        v-model="form.donateContent"
        :maxlength="300"
        :showWordLimit="true"
        placeholder="亲爱的小主，捐出你们的爱心，让社区更温暖"
      />
    </div>
    <div class="oItem-b">
      <p>上传图片</p>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.donatePic1"
        :activityPicture.sync="form.donatePic"
        ref="load"
      ></v-upload>
      <p class="upload_text">建议单个图片不超过5M。</p>
    </div>
    <div class="submit" @click="submit">确认提交</div>
  </div>
</template>

<script>
import { postDonateUrl } from "./api.js";
import { toRegister } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "wishes",
  data() {
    return {
      timer: null,
      form: {
        donatePic: "",
        donateContent: "",
      },
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  mounted() {
    // this.getActivityList();
  },
  methods: {
    async submit() {
      if (await toRegister(this.$route.path, this.$route.query, "公益捐赠")) {
        if (!this.form.donateContent) {
          this.$toast("捐赠内容为空");
          return;
        }
        if (this.$refs.load.status == 3) {
          this.$toast("图片正在上传中");
          return;
        }
        this.postData();
      }
    },
    postData() {
      let params = {
        houseId: this.roomId,
        donateUserId: this.userId,
        donateContent: this.form.donateContent,
        donatePic: this.form.donatePic,
      };
      this.$axios.post(`${postDonateUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast("提交成功");
          // this.timer = setTimeout(() => {
          //   this.$router.replace({
          //     name: "myDonate",
          //   });
          // }, 200);
          this.$router.replace({
            name: "myDonate",
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.donate {
  min-height: 100vh;
  background: #f3f4f9;
  .oItem-t,
  .oItem-c,
  .oItem-b {
    background: #fff;
  }
  .oItem-t {
    padding: 32px 0px 16px 32px;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #1a1c34;
      line-height: 50px;
    }
  }
  .oItem-c {
    padding: 32px;
    ::v-deep .van-field {
      background: #f7f8fa;
    }
  }
  .oItem-b {
    padding: 32px;
    p {
      font-size: 32px;
      font-weight: bold;
      color: #666666;
      line-height: 44px;
    }
    .oItem-b_upload {
      width: 160px;
      height: 160px;
      background: #f7f8fa;
      margin: 32px 0 16px;
      ::v-deep .van-uploader__preview {
        border: 2px dashed #eee;
      }
      ::v-deep .van-uploader__preview-image {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        margin: 0;
      }
    }
    .upload_text {
      font-size: 24px;
      font-weight: 400;
      color: #96979b;
      line-height: 32px;
    }
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    width: 100%;
    height: 96px;
    background: #178af7;
    text-align: center;
    font-size: 32px;
    line-height: 96px;
    color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
}
</style>
<style lang="less">
.wishes {
  .van-radio__label {
    font-size: 30px;
  }
}
</style>
